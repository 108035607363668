/**
 * This file let you add new content types to the master data collection magento-page-builder component.
 */
import dynamic from 'next/dynamic'

const SliderMagazine = dynamic(() =>
	import(
		'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/master-data-collection/content-types/slider-magazine'
	)
)

const masterDataCollectionCustomContentTypes = {
	'default-slider-magazine': SliderMagazine,
}

export default masterDataCollectionCustomContentTypes
